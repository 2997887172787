import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import WizardWrapper from './WizardWrapper';

function RegistrationForm(props) {

    const [validated, setValidated] = useState(false);

    const dispatch = props.dispatch;
    const app_state = props.state.app_state;
    const task_url = props.state.task_info?.task_url

    useEffect(() => {
        if (app_state === "registration") {
            setValidated(false);
        }
    }, [app_state])

    const handleApiError = (error) => {
        if (error.response && error.response.status === 429) {
            props.dispatch({ type: 'set_error', payload: {error: { error: "Server Error", message: error.response.data?.detail || "Rate limited" } }})
        }
        else if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            dispatch({ type: 'set_error', payload: {error: { error: "Server Error", message: `The following server error occured ${error.response.status}` }} })

        } else {
            dispatch({ type: 'set_error', payload: {ererrorrpr: { error: "Network Error", message: "A connection error occured." } }})
        }
        console.log(error);
    };


    useEffect(() => {
        if (app_state === "wait_for_task") {
            const interval = setInterval(() => {
                axios.get(task_url)
                    .then(function (response) {
                        dispatch({ type: 'set_task_info', payload: { task_info: response.data } })
                        if (response.data?.task_status === "FAILURE") {
                            dispatch({ type: 'set_error', payload: { error: response.data.task_result } })
                        } else if (response.data?.task_status === "SUCCESS") {
                            dispatch({ type: 'set_state', payload: { app_state: "assessment" } })
                        }
                        console.log(response);
                    })
                    .catch(handleApiError);

            }, 500);
            return () => { console.log("clearing interval ", interval); clearInterval(interval) };
        }
    }, [app_state, task_url, dispatch]);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === true) {
            props.dispatch({ type: 'set_state', payload: { app_state: "loading" } })
            axios.post('/api/v1/assessment/', props.state.form_state)
                .then(function (response) {
                    props.dispatch({ type: 'set_task_info', payload: { task_info: response.data } })
                    console.log(response);
                })
                .catch(handleApiError);
        }
    };

    const onChange = (event) => {
        props.dispatch({ type: 'set_form_value', payload: { target: event.target.name, value: event.target.value } });
    }

    return (
        <>
            <WizardWrapper>
                <h4>Participant Registration</h4>
                
                <p>Please provide your participant ID, gender and date of birth to proceed to the tasks for today’s assessment. </p>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className="form-floating">
                    <Row>
                        <Form.Group className="mb-3 col-md-12" controlId="registrationFormParticipantID">
                            <Form.Label>Participant ID</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="participant_id"
                                placeholder="Participant ID"
                                value={props.state.form_state.participant_id}
                                onChange={onChange}
                                disabled={props.state.app_state !== "registration"}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3 col-md-6" controlId="registrationFormParticipantGender">
                            <Form.Label>Gender</Form.Label>
                            <Form.Select
                                aria-label="Participant Gender"
                                required name="gender"
                                onChange={onChange}
                                value={props.state.form_state.gender}
                                disabled={props.state.app_state !== "registration"}
                            >
                                <option disabled value="">Please Select</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3 col-md-6" controlId="registrationFormParticipantDOB">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                                required
                                type="date"
                                name="dob"
                                onChange={onChange}
                                value={props.state.form_state.dob}
                                max={new Date().toISOString().split("T")[0]}
                                min='1899-01-01'
                                disabled={props.state.app_state !== "registration"}

                            />
                            {/* <Form.Text className="text-muted">
                                    
                                </Form.Text> */}
                        </Form.Group>
                    </Row>
                    <Row>

                    </Row>

                    <Row>
                        <Button variant="primary" type="submit" className="mt-5" disabled={props.state.app_state !== "registration"}>
                            {props.state.app_state === "registration" ? "Submit" : <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="ms-2">Loading...</span></>}
                        </Button>
                    </Row>
                </Form>
            </WizardWrapper>
        </>
    );
}

export default RegistrationForm;