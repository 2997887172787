import WizardWrapper from "./WizardWrapper"
import thumbsup from "./assets/thumbs-up.svg"

const Done = () => {

    return (
        <WizardWrapper>
            <div className="text-center m-5">
            <img src={thumbsup} alt="thumbs up" style={{height: "250px", width: "auto"}}/>
            <h1>Thank you!</h1>
            The assessment is complete. You can close this window now. 
            </div>
        </WizardWrapper>

    )


}

export default Done;