import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ErrorModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton className="modal-header-primary">
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.state.error?.error === "KeyError" ? "Participant Not Found." : props.state.error?.error}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                During validation of your details, the following error occured:
                </p>
                <p>
                    {props.state.error?.message || "[Unknown Network Error]"}
                </p>
                <p>You can adjust the details in the form and try again.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ErrorModal;