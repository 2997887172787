import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import './CogstateAssessment.css';

function CogstateAssessment(props) {
    const nofity = useRef(true);
    const dispatch = props.dispatch;

    const assessment = props.assessment;

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === "Screener_Error" || event.data === "Screener_Success") {
                console.log(event)
                const toastId = toast.info("Saving Assessment State.", { autoClose: false });

                axios.post('/api/v1/assessmentstate/', { state: event.data, return_key: assessment.return_key })
                    .then(function (response) {
                        toast.update(toastId, { render: "Assessment State Saved.", type: toast.TYPE.SUCCESS, autoClose: 2000, className: 'rotateY animated', pauseOnFocusLoss: false, pauseOnHover: false });
                        console.log(response);
                    })
                    .catch(function (error) {
                        toast.update(toastId, { render: "Error Saving Assessment State. Don't worry, your results were still recorded.", type: toast.TYPE.ERROR, autoClose: 5000, className: 'rotateY animated', pauseOnFocusLoss: false, pauseOnHover: false });
                        console.log(error);
                    });
            }

            if (event.data === "Screener_Error") {
                dispatch({ type: 'set_error', payload: { error: { error: "Assessment Error", message: "Assessment tool reported a problem. You may have to redo the assessment." } } })
            } else if (event.data === "Screener_Success") {
                dispatch({ type: 'set_state', payload: { app_state: "done" } })
            }
        }

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [dispatch, assessment]);

    useEffect(() => {
        if (nofity.current) {
            toast.info(<>Starting <b>{assessment?.session_name || ''}</b></>, { autoClose: 5000, pauseOnFocusLoss: false, pauseOnHover: false });
            nofity.current = false;
        }
        return () => { }
    }, [assessment]);

    return (
        <div id="cogstate-embed">
            <iframe width="100%" height="100%" frameborder="0" title="Cogstate Assessment" src={props.assessment.url} />;
        </div>
    )
}

export default CogstateAssessment;