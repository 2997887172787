import './App.css';
import { useReducer, createContext } from 'react';
import RegistrationForm from './RegistrationForm';
import CogstateAssessment from './CogstateAssessment';
import ErrorModal from './ErrorModal';
import { ToastContainer } from 'react-toastify';
import Done from "./Done";
import 'react-toastify/dist/ReactToastify.css';



const initialFormState = { participant_id: "", dob: "", gender: "" }
const initialState = {
    app_state: 'registration',
    form_state: initialFormState,
    task_info: null,
    assessment: null,
    error: null
};

function reducer(state, action) {
    switch (action.type) {
        case 'set_state':
            if (action.payload.app_state === "registration") {
                return { ...state, app_state: action.payload.app_state, task_info: null, error: null, assessment: null };
            } else {
                return { ...state, app_state: action.payload.app_state };
            }
        case 'set_task_info':
            return { ...state, app_state: "wait_for_task", task_info: action.payload.task_info };
        case 'set_error':
            return { ...state, app_state: "error", error: action.payload.error};
        case 'set_form_value':
            const new_form_state = { ...state.form_state, [action.payload.target]: action.payload.value }
            return { ...state, form_state: new_form_state };
        default:
            throw new Error();
    }
}

const AppStateDispatch = createContext(null);


function App() {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppStateDispatch.Provider value={dispatch}>
            <ErrorModal
                show={state.app_state === "error"}
                onHide={() => dispatch({ type: 'set_state', payload: { app_state: "registration" } })}
                state={state}
            />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
            />
            <div className="App">
                <header className="App-header">
                    {(state.app_state !== "assessment" && state.app_state !== "done") && <RegistrationForm dispatch={dispatch} state={state} />}
                    {state.app_state === "assessment" && <CogstateAssessment dispatch={dispatch} assessment={state.task_info.task_result} />}
                    {(state.app_state === "done") && <Done dispatch={dispatch} state={state} />}

                </header>
            </div>
        </AppStateDispatch.Provider>
    );
}

export default App;
