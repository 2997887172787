import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

import logo from "./assets/head-brain.svg";
import latrobelogo from "./assets/latrobe.svg"
import griffithlogo from "./assets/griffith.svg"

import "./WizardWrapper.css";

const WizardWrapper = (props) => {

    return (
        <>
            <Container className="d-flex align-items-center mx-auto" id="wizard-container">
                <Row className="g-0 justify-content-center">
                    <Col className="col-md-4 col-12 offset-lg-1 mx-0 px-0">
                        <div id="title-container">
                            <img className="filter-red" src={logo} alt="brain-logo" />
                            <h2>LaTCH</h2>
                            <h3>Memory Intervention</h3>
                            <p>If you are not a part of the LaTCH memory intervention for brain cancer survivors, and would like to know more information, please email: <a href="mailto:brains@latrobe.edu.au?subject=LaTCH%20memory%20intervention%20for%20brain%20cancer%20survivors">brains@latrobe.edu.au</a></p>
                        </div>
                    </Col>
                    <Col className="col-xl-6 col-lg-7 col-md-8 col-12 mx-0 px-0">
                        <div id="wizard-content-container">
                            {props.children}
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <footer className="d-flex flex-wrap justify-content-between align-items-center ">
                    <p className="col-md-3 mb-0 text-muted d-xs-none d-none d-md-flex"><img src={latrobelogo} alt="latrobe logo" style={{ height: "50px", width: "auto" }} /></p>
                    <p className="col-md-6 col-sm-12 d-flex align-items-center justify-content-center mb-3 px-2 mb-md-0 me-md-auto text-muted text-center mx-auto" style={{ fontSize: "0.6em" }}>This site is part of a joint research project between Griffith University and LaTrobe University<br />© 2022 Griffith University, CRICOS Provider - 00233E</p>
                    <p className="nav col-md-3 justify-content-end d-xs-none d-none d-md-flex">
                        <img src={griffithlogo} alt="griffith logo" style={{ height: "50px", width: "auto" }} />
                    </p>
                </footer>
            </Container>
        </>
    )
}

export default WizardWrapper;
